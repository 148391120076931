@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&amp;display=swap');

.album,
.album:before,
.album:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.album {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;
}

.album .responsive-container-block {
  min-height: 75px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.album .responsive-container-block.bg {
  max-width: 1380px;
  margin: 0;
  justify-content: space-between;
}

.album .imgs img {
  width: 100%;
  margin: 15px 0 0 0;
  border-radius: 3px;

}

.album div .imgs {
  position: relative;
  max-width: 100%;
  transition: transform 0.3s ease;
  cursor: pointer; /* Indicate clickable images */
}

.album .imgs.img {
  display: flex;
  width: 100%;
  height: auto;
}

.album .imgs:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.721), transparent);
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.album .responsive-container-block.img-cont {
  flex-direction: column;
  max-width: 50%;
  height: auto;
  margin: 0;
  align-items: center;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(16, 14, 14, 0.505); /* Black background with opacity */
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  margin: 15% auto; /* 15% from the top and centered */
  display: block;
  width: 90%;
  max-width: 700px;
}

.close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}

.modal-content img {
  width: 100%;
  height: auto;
}

@media (max-width: 980px) {
  .album .responsive-container-block.img-cont {
    flex-direction: row;
    max-width: 100%;
    justify-content: space-around;
  }

  .album .imgs. img {
    margin: 0 0 18px 0;
    max-width: 90%%;
  }
}

@media (max-width: 900px) {
  .album .responsive-container-block.img-cont {
    flex-direction: column;
    align-items: center;
    padding: 5px;
  }

  .album .imgs .img {
    max-width: 100%;
    margin-bottom: 5px;
  }

  .album .responsive-container-block.img-cont {
    margin-bottom: 0;
  }
}

@media (max-width: 500px) {
  .album .imgs img {
    max-width: 100%;
    margin-bottom: 5px;
  }

  .album .responsive-container-block.img-cont {
    flex-direction: column;
    align-items: center;
    padding: 5px;
  }
}